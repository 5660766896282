var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function MaxHeight(value) {
  (this || _global).value = value;

  (this || _global).match = function (options) {
    return (this || _global).value >= options.height;
  };
}

function MinHeight(value) {
  (this || _global).value = value;

  (this || _global).match = function (options) {
    return (this || _global).value < options.height;
  };
}

function MaxWidth(value) {
  (this || _global).value = value;

  (this || _global).match = function (options) {
    return (this || _global).value >= options.width;
  };
}

function MinWidth(value) {
  (this || _global).value = value;

  (this || _global).match = function (options) {
    return (this || _global).value < options.width;
  };
}

function Orientation(value) {
  (this || _global).value = value;

  (this || _global).match = function (options) {
    return (this || _global).value === options.orientation;
  };
}

exports = function Query(type, value) {
  switch (type) {
    case "max-height":
      return new MaxHeight(value);

    case "min-height":
      return new MinHeight(value);

    case "max-width":
      return new MaxWidth(value);

    case "min-width":
      return new MinWidth(value);

    case "orientation":
      return new Orientation(value);

    default:
      throw new Error(value);
  }
};

export default exports;