var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

function And(left, right) {
  (this || _global).left = left;
  (this || _global).right = right;

  (this || _global).match = function (options) {
    return left.match(options) && right.match(options);
  };
}

function Or(left, right) {
  (this || _global).left = left;
  (this || _global).right = right;

  (this || _global).match = function (options) {
    return left.match(options) || right.match(options);
  };
}

exports = function Operator(type, left, right) {
  switch (type) {
    case "and":
      return new And(left, right);

    case ",":
      return new Or(left, right);

    default:
      throw new Error(value);
  }
};

export default exports;